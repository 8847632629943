<template>
  <div class="wrapper">
    <div :id="key" class="app" v-for="(app, key) in this.apps">
      <div class="app__info">
        <div class="app__title-group">
          <img v-if="app.icon" :src="`${publicPath}apps/${app.icon}`" class="app__icon"/>
          <div class="app__title" v-html="app.title"></div>
        </div>
        <div class="app__description" v-html="app.description"></div>
        <div class="app__markets">
          <a v-if="app.appstore" :href="app.appstore" target="_blank" :class="['app__button_appstore', app.platform]"></a>
        </div>
      </div>
      <div class="app__phone">
        <img :src="`${publicPath}apps/${app.screenshot}`" :class="['app__screen',app.platform]"/>
        <div class="app__shadow"></div>
      </div>
      <div class="app__background"></div>
    </div>
  </div>
</template>

<script>
import applications from '../../json/applications.json'

export default {
  name: "index",
  data: function () {
    return {
      apps: applications,
      publicPath: process.env.BASE_URL
    }
  },
  components: {},
  methods: {}
}
</script>
<style lang="less">
@import "style.less";
</style>